import consumer_journey_payCenterDetails from '@/lib/data-service/haolv-default/consumer_journey_payCenterDetails'
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'
import consumer_journey_orderTotal from '@/lib/data-service/haolv-default/consumer_journey_orderTotal'
import consumer_journey_pendingTrip from '@/lib/data-service/haolv-default/consumer_journey_pendingTrip'
import consumer_evection_addOrUpdate from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate";
import BackBtn from '@/component/backBtn/index.vue'
import StopDataBox from '@/page/admin/travel-book/flight/component/stopDataBox/index.vue'

import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import moment from 'moment'
import aes_encrypt from 'nllib/src/common/aes/aes_encrypt';
import AvailableTravellerSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/available-traveller-selector/1.0.0/index.vue'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import getEvectionAllType from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0";
export default {
    data() {
        return {
            loading: false,
            evectionNo: '',
            evectionType: '',
            evectionRequired: '',
            workTravelType: '',
            applyId: '',
            evectionDetailType: '',
            evectionInfo: {},
            evectionParams: {},
            journeyList: [],
            flightList: [],
            flightOrderList: [],
            hotelList: [],
            hotelOrderList: [],
            trainList: [],
            trainOrderList: [],
            priceInfo: {},

            temp: '',

            newJourneyItem: {},
            selectedIdList: [],
            newJourneyBusinessType: '',

            overProofDialog: false
        }
    },
    components: {
        StopDataBox,
        BackBtn,
        AvailableTravellerSelector,
        TravelerMultipleSelector
    },
    created() {
    },
    mounted() {
        this.init()

        let evectionParams = {
            vm: this
        }
        const evectionQuery = getEvectionAllType(evectionParams)
        this.evectionDetailType = evectionQuery.evectionDetailType

        this.$refs.aAvailableTravellerSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(`btn_ok_click_event_handler`);
            console.log(args);
            let params = {
                journeyId: this.newJourneyItem.journeyId,
                applyId: this.applyId,
                endDate: this.newJourneyItem.returnTime,
                evectionNo: this.evectionNo,
                evectionType: 2, // 1是个人出差，2是出差单出差
                startDate: this.newJourneyItem.departTime,
                travellerList: this.selectedIdList,
                personType: 2,
                cabin: '',
            };
            consumer_evection_addOrUpdate(params).then(res => {
                this.goNewJourney()
            })
        };

        this.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            let params = {
                journeyId: this.newJourneyItem.journeyId,
                applyId: this.applyId,
                endDate: this.newJourneyItem.returnTime,
                evectionNo: this.evectionNo,
                evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                startDate: this.newJourneyItem.departTime,
                travellerList: this.selectedIdList,
                personType: 2,
                cabin: '',
            };
            consumer_evection_addOrUpdate(params).then(res => {
                this.goNewOtherJourney()
            })
        }
    },
    activated() {
    },
    deactivated() {
        clearInterval(this.temp)
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒')
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        async init() {
            this.evectionNo = this.$route.query.evectionNo
            this.evectionType = parseInt(this.$route.query.evectionType)
            this.applyId = this.$route.query.applyId === undefined ? '' : this.$route.query.applyId
            await this.getDetail()
            this.pendingTrip()
            this.getAllPrice()
        },
        pendingTrip() {
            let params = {
                evectionNo: this.evectionNo
            }
            consumer_journey_pendingTrip(params).then(res => {
                this.journeyList = res.datas.list || []
            })
        },
        getAllPrice() {
            let orderNos = [...this.flightOrderList, ...this.hotelOrderList, ...this.trainOrderList]
            const params = {
                orderNos
            }
            consumer_journey_orderTotal(params).then(res => {
                let priceInfo = res.datas
                let i = 1
                if (priceInfo && priceInfo.flightAmountsList) {
                    priceInfo.flightAmountsList.forEach(value => {
                        value.index = i
                        i++
                    })
                }
                if (priceInfo && priceInfo.trainTotalAmountList) {
                    priceInfo.trainTotalAmountList.forEach(value => {
                        value.index = i
                        i++
                    })
                }
                if (priceInfo && priceInfo.hotelTotalAmountList) {
                    priceInfo.hotelTotalAmountList.forEach(value => {
                        value.index = i
                        i++
                    })
                }
                this.priceInfo = priceInfo
            })
        },
        async getDetail() {
            const params = {
                evectionNo: this.evectionNo
            }
            this.loading = true
            let [err, res] = await awaitWrap(consumer_journey_payCenterDetails(params))
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            this.evectionInfo = res.datas
            //let journeys = res.datas.tripList
            //this.journeyList = journeys
            let hasOverproofOrder = false
            let flightOrderList = res.datas.flightDetail && res.datas.flightDetail.orderList ? res.datas.flightDetail.orderList : []
            let flightOverproofList = res.datas.flightDetail && res.datas.flightDetail.overproofList ? res.datas.flightDetail.overproofList : []
            if (flightOverproofList.length > 0) {
                hasOverproofOrder = true
            }
            let flightList = [...flightOrderList, ...flightOverproofList]
            let fOrderList = []
            flightOrderList.forEach(value => {
                fOrderList.push(value.orderNo)
            })
            flightList.forEach(value => {
                if (value.milliseconds && value.milliseconds > 0) {
                    value.millisecondsNum = parseInt(value.milliseconds) * 1000
                } else {
                    value.millisecondsNum = null
                }
            })
            this.flightOrderList = fOrderList
            this.flightList = flightList

            let hotelUnPaidHotelList = res.datas.hotelDetail && res.datas.hotelDetail.unPaidHotelList ? res.datas.hotelDetail.unPaidHotelList : []
            let hotelOverproofApproveHotelList = res.datas.hotelDetail && res.datas.hotelDetail.overproofApproveHotelList ? res.datas.hotelDetail.overproofApproveHotelList : []
            if (hotelOverproofApproveHotelList.length > 0) {
                hasOverproofOrder = true
            }
            let hOrderList = []
            let hotelList = [...hotelUnPaidHotelList, ...hotelOverproofApproveHotelList]
            hotelUnPaidHotelList.forEach(value => {
                hOrderList.push(value.orderNo)
            })
            hotelList.forEach(value => {
                if (value.milliseconds && value.milliseconds > 0) {
                    value.millisecondsNum = parseInt(value.milliseconds) * 1000
                } else {
                    value.millisecondsNum = null
                }
            })
            this.hotelOrderList = hOrderList
            this.hotelList = hotelList

            let payOrderList = res.datas.trainDetail && res.datas.trainDetail.payOrderList ? res.datas.trainDetail.payOrderList : [] // 获取可用火车票行程
            let noPayOrderList = res.datas.trainDetail && res.datas.trainDetail.noPayOrderList ? res.datas.trainDetail.noPayOrderList : [] // 获取不可用火车票行程
            if (noPayOrderList.length > 0) {
                hasOverproofOrder = true
            }
            let tOrderList = []
            let trainList = [...payOrderList, ...noPayOrderList]
            payOrderList.forEach(value => {
                tOrderList.push(value.orderNo)
            })
            trainList.forEach(value => {
                if (value.milliseconds && value.milliseconds > 0) {
                    value.millisecondsNum = parseInt(value.milliseconds) * 1000
                } else {
                    value.millisecondsNum = null
                }
            })
            this.trainOrderList = tOrderList
            this.trainList = trainList
            clearInterval(this.temp)
            this.timer()
            if (hasOverproofOrder) {
                this.overProofDialog = true
            }

        },
        timer() {
            //页面多个定时器 //主要逻辑都在这页面更新
            this.temp = setInterval(() => {
                let isReact = false
                let flightList = [...this.flightList]
                flightList.forEach((item, index) => {
                    if (item.millisecondsNum) {
                        item.millisecondsNum = item.millisecondsNum - 1000
                        // this.$set(item, 'surplusSec', surplusSec);
                    } else if (item.millisecondsNum === null) {

                    } else {
                        isReact = true
                        item.millisecondsNum = null
                    }
                });
                this.flightList = flightList
                let hotelList = [...this.hotelList]
                hotelList.forEach((item, index) => {
                    if (item.millisecondsNum) {
                        item.millisecondsNum = item.millisecondsNum - 1000
                        // this.$set(item, 'surplusSec', surplusSec);
                    } else if (item.millisecondsNum === null) {

                    }  else {
                        isReact = true
                        item.millisecondsNum = null
                    }
                });
                this.hotelList = hotelList
                let trainList = [...this.trainList]
                trainList.forEach((item, index) => {
                    if (item.millisecondsNum) {
                        item.millisecondsNum = item.millisecondsNum - 1000
                        // this.$set(item, 'surplusSec', surplusSec);
                    } else if (item.millisecondsNum === null) {

                    }  else {
                        isReact = true
                        item.millisecondsNum = null
                    }
                });
                this.trainList = trainList
                if (isReact) {
                    this.init()
                }
            }, 1000);
        },
        toNewJourney(item) {

            this.selectedIdList = []
            if (item.travellerIds) {
                let selectedIdList = item.travellerIds.split(',')
                let lastSelectedIdList = []
                selectedIdList.forEach(value => {
                    lastSelectedIdList.push(parseInt(value))
                })
                this.selectedIdList = lastSelectedIdList
            }

            // 如果只有一个出行人，就不弹出选人弹窗
            if (this.selectedIdList.length === 0) {
                let params = {
                    journeyId: item.journeyId,
                    applyId: this.applyId,
                    endDate: item.returnTime,
                    evectionNo: this.evectionNo,
                    evectionType: 2, // 1是个人出差，2是出差单出差
                    startDate: item.departTime,
                    travellerList: this.selectedIdList,
                    personType: 2,
                    cabin: '',
                };
                consumer_evection_addOrUpdate(params).then(res => {
                    this.goNewJourney()
                })
                return
            }
            this.newJourneyItem = item
            const journeyId = item.journeyId
            this.$refs.aAvailableTravellerSelector.init({
                get_params() {
                    const p = {
                        applyJourneyId: journeyId,
                    };
                    return p;
                },
            });
            this.$refs.aAvailableTravellerSelector.show()
        },
        goNewJourney() {
            // 一定是有出差单
            let queryParams = {}
            const key = 'params';
            if (this.newJourneyItem.businessType === 1) {
                // 机票
                queryParams = {
                    redirectType: '001',
                    startDate: this.newJourneyItem.departTime,
                    endDate: this.newJourneyItem.returnTime,
                    fromStationCode: this.newJourneyItem.departCityId,
                    fromStationName: this.newJourneyItem.departCityName,
                    toStationCode: this.newJourneyItem.toCityId,
                    toStationName: this.newJourneyItem.toCityName,
                    flightType: 1,
                    journeyId: this.newJourneyItem.journeyId,
                    applyId: this.applyId
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-flight-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: 2,
                    }
                })
            } else if (this.newJourneyItem.businessType === 3) {
                // 酒店
                queryParams = {
                    redirectType: '001',
                    cityId: this.newJourneyItem.toCityId,
                    cityName: this.newJourneyItem.toCityName,
                    checkInDate: this.newJourneyItem.departTime,
                    checkOutDate: this.newJourneyItem.returnTime,
                    keyword: '',
                    hotelStar: '',
                    journeyId: this.newJourneyItem.journeyId
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-hotel-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: 2,
                    }
                })
            } else {
                queryParams = {
                    redirectType: '001',
                    time: this.newJourneyItem.departTime,
                    fromStationCode: this.newJourneyItem.departCityId,
                    toStationCode: this.newJourneyItem.toCityId,
                    onlySearch: 0,
                    journeyId: this.newJourneyItem.journeyId,
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-train-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: 2,
                    }
                })
            }
        },
        toNewOtherJourney(businessType) {
            this.selectedIdList = []
            this.newJourneyBusinessType = businessType
            this.$refs.aTravelerMultipleSelector.init({
                situation: '100',
                select_mode:businessType === 2 ? 'single' : 'multiple',
                businessType: businessType,
                dialog: {
                    title: '请选择出行人',
                    width: '1000px'
                },
            });
            this.$refs.aTravelerMultipleSelector.show()
        },
        goNewOtherJourney() {
            if (this.newJourneyBusinessType === 1) {
                this.toSearchFlight()
            }
            if (this.newJourneyBusinessType === 2) {
                this.toSearchTrain()
            }
            if (this.newJourneyBusinessType === 3) {
                this.toSearchHotel()
            }
        },
        // 新增机票
        async toSearchFlight() {
            let queryParams = {}
            const key = 'params';

            let fromStationCode = ''
            let fromStationName = ''
            let toStationCode = ''
            let toStationName = ''
            let startDate = ''
            let endDate = ''
            this.flightList.forEach(value => {
                fromStationCode = value.segmentVO[0].depCityCode
                fromStationName = value.segmentVO[0].depCityName
                toStationCode = value.segmentVO[0].arrCityCode
                toStationName = value.segmentVO[0].arrCityName
                startDate = value.segmentVO[0].depDate
            })
            queryParams = {
                fromStationCode: fromStationCode,
                fromStationName: fromStationName,
                toStationCode: toStationCode,
                toStationName: toStationName,
                startDate: startDate,
                endDate: endDate,
                flightType: 1,
                redirectType: '001',
                journeyId: 0,
                applyId: '',
            }
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    params: encrypted,

                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType
                }
            })
        },
        // 新增酒店
        async toSearchHotel() {
            let queryParams = {}
            const key = 'params';

            let cityId = '70011'
            let cityName = '广州'
            let checkInDate = this.$moment().locale('zh-cn').format('YYYY-MM-DD')
            let checkOutDate = this.$moment().add(1,'days').locale('zh-cn').format('YYYY-MM-DD')
            let hotelName = ''
            this.hotelList.forEach(value => {
                cityId = value.cityId
                cityName = value.cityName
                checkInDate = value.checkInDate
                checkOutDate = value.checkOutDate
                hotelName = value.hotelName
            })
            queryParams = {
                cityId: cityId,
                cityName: cityName,
                checkInDate: checkInDate,
                checkOutDate: checkOutDate,
                keyword: hotelName,
                hotelStar: '',
                redirectType: '001',
                journeyId: 0,
                applyId: '',
            }
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-hotel-search',
                query: {
                    params: encrypted,

                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType
                }
            })
        },
        // 新增火车票
        async toSearchTrain() {
            let queryParams = {}
            const key = 'params';

            let fromStationCode = 'GZQ'
            let toStationCode = 'BJP'
            let startDate = this.$moment().format('YYYY-MM-DD')
            if (this.trainList.length > 0) {
                this.trainList.forEach(value => {
                    fromStationCode = value.fromStationCode
                    toStationCode = value.toStationCode
                    startDate: value.startDate
                })
            }
            queryParams = {
                fromStationCode: fromStationCode,
                toStationCode: toStationCode,
                time: startDate,
                redirectType: '001',
                journeyId: 0,
                applyId: '',
            }
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-train-search',
                query: {
                    params: encrypted,

                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType
                }
            })
        },
        getStopMsg(val, index, sIndex) {
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.flightNo
                }
            }
            this.flightList[index].segmentVO[sIndex].stopLoad = true
            let flightsList = JSON.parse(JSON.stringify(this.flightList))
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].segmentVO[sIndex].stopData = res.datas
                flightsList[index].segmentVO[sIndex].stopLoad = false
                this.flightList = flightsList
            }).catch(() => {
                flightsList[index].segmentVO[sIndex].stopLoad = false
                this.flightList = flightsList
            })
        },
        toPay() {
            let orderNos = []
            orderNos = [...this.flightOrderList, ...this.trainOrderList, ...this.hotelOrderList]
            if (orderNos.length === 0) {
                this.$message({
                    message: '没有可以支付的订单',
                    type: "warning"
                })
                return
            }
            const params = {
                orderNos: orderNos.join(','),
                evectionNo: this.evectionNo,
                flightOrderList: this.flightOrderList.length > 0 ? this.flightOrderList.join(',') : ''
            }
            this.$router.push({
                name: 'admin-pay',
                query: params
            })
        },
        handleClose() {
            this.overProofDialog = false
        },
    }
}
