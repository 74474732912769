export default {
    data() {
        return {}
    },
    prop: {
        stopData: {
            type: Object,
            default: () => {}
        },
        stopLoad: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {}
}