// consumer_journey_pendingTrip
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// https://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/API/pendingTripUsingPOST
const consumer_journey_pendingTrip = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/pendingTrip',
        data: pParameter,
        // enable_error_alert: true // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_pendingTrip;