// consumer_journey_payCenterDetails
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/%E8%A1%8C%E7%A8%8B%E9%A2%84%E8%AE%A2/payCenterDetailsUsingPOST
const consumer_journey_payCenterDetails = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/payCenterDetails',
        data: pParameter,
        enable_error_alert: true // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_payCenterDetails;