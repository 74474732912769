// consumer_evection_addOrUpdate 新增或修改出差单
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.26:8765/doc.html#/haolv-consumer/t-us-evection-controller/addOrUpdateUsingPOST_4
const consumer_evection_addOrUpdate = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/evection/addOrUpdate',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_evection_addOrUpdate;